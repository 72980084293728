<template>
  <v-form>
    <v-row align="center">
      <v-col cols="12" lg="3">
        <v-text-field v-model="editedItem.name" label="Name" hide-details v-if="isBaseCompany" />
        <v-autocomplete v-model="editedItem.name" label="Name" :items="configNames" hide-details
          @change="onNameSelected" v-else />
      </v-col>
      <v-col cols="12" lg="3">
        <v-select v-model="editedItem.type" label="Type" :items="types" hide-details :disabled="!isBaseCompany" />
      </v-col>
      <v-col cols="12" lg="3">
        <type-field v-model="editedItem.value" label="Value" :type="editedItem.type" />
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field v-model="editedItem.description" label="Description" hide-details />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import TypeField from '../custom/TypeField.vue'
import { TYPES } from '../custom/types'
import { get } from 'vuex-pathify';

export default {
  components: { TypeField },
  props: {
    value: Object,
  },
  data() {
    return {
      TYPES,
      types: Object.values(TYPES),
      editedItem: {
        name: '',
        value: '',
        type: TYPES.STRING,
        description: '',
      },
      defaultItem: {
        name: '',
        value: '',
        type: TYPES.STRING,
        description: '',
      },
    }
  },
  computed: {
    ...get('company', ['selectedCompanyId']),
    configNames() {
      return this.$store.getters['configs/getConfigNames']
    },
    isBaseCompany() {
      return this.$store.getters['configs/isBaseCompany'](this.selectedCompanyId)
    }
  },
  created() {
    this.$store.dispatch('configs/loadBaseConfigurations')

    this.$emit('input', {
      assign: (item) => {
        this.editedItem = Object.assign({}, item)
      },
      assignDefaults: () => {
        this.editedItem = Object.assign({}, this.defaultItem)
      },
      getData: this.getData,
    })
  },
  methods: {
    getData() {
      return this.editedItem
    },
    onNameSelected() {
      this.editedItem.type = this.editedItem.name
        ? this.$store.getters['configs/getConfig'](this.editedItem.name)?.type
        : ''
    }
  },
}
</script>
