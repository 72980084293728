<template>
  <div>
    <div
      class="mx-1"
      style="display: flex; padding: 15px 20px; border-radius: 5px; background: #f5f5f5;"
    >
      <div style="flex: 1">
        <config-form v-model="form" />
      </div>

      <app-btn
        class="ml-5 mt-2"
        @click="add"
      >
        Add
      </app-btn>
    </div>

    <div class="mt-8 mx-5">
      <editable-config-table
        v-model="value"
        @remove="remove"
      />
    </div>
  </div>
</template>

<script>
  import ConfigForm from './ConfigForm.vue'
  import EditableConfigTable from './EditableConfigTable.vue'

  export default {
    components: { ConfigForm, EditableConfigTable },
    props: {
      value: { type: Array, default: () => [] },
    },
    data () {
      return {
        form: null,
      }
    },
    methods: {
      add () {
        const items = this.value
        items.unshift(this.form.getData())

        this.$emit('input', items)
        this.form.assignDefaults()
      },
      remove (item) {
        const items = this.value
        const index = items.indexOf(item)
        items.splice(index, 1)

        this.$emit('input', items)
      },
    },
  }
</script>
