<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title class="text-h5">
        File Collections
      </v-card-title>

      <v-card-text>
        <v-tabs v-model="tab" @change="onTabSelected">
          <v-tab v-for="group in fileGroups" :key="group">
            {{ group }}
          </v-tab>
        </v-tabs>

        <div class="mt-3 d-flex" style="gap: 10px">
          <div v-for="file in groupFiles" class="d-inline-flex justify-center align-center py-2 px-5"
            style="background: #f5f5f5; border-radius: 7.5px; cursor: pointer; gap: 7.5px" @click="chooseFile(file)">
            <v-icon x-large color="#749ef2">mdi-file</v-icon>
            <span>{{ file.name }}</span>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      dialog: false,
      tab: null
    }
  },
  computed: {
    ...get('company', ['selectedCompanyId']),
    selectedGroup() {
      return this.fileGroups[this.tab]
    },
    fileGroups() {
      return this.$store.getters['filesLibrary/getFileGroups']
    },
    groupFiles() {
      return this.$store.getters['filesLibrary/getGroupFiles'](this.selectedGroup)
    }
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.close()
      }
    },
    selectedCompanyId() {
      this.$store.dispatch('filesLibrary/loadFileGroups', this.selectedCompanyId)
    }
  },
  created() {
    this.$emit('input', {
      show: () => {
        this.show()
      },
      close: this.close,
    })

    this.$store.dispatch('filesLibrary/loadFileGroups', this.selectedCompanyId)
  },
  methods: {
    show() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onTabSelected() {
      this.$store.dispatch('filesLibrary/loadGroupFiles', this.selectedGroup)
    },
    chooseFile(file) {
      this.$emit('select', file)
      this.close()
    }
  },
}
</script>
